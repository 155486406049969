const Utils = {
    lineEq: (y2, y1, x2, x1, currentVal) => {
        var m = (y2 - y1) / (x2 - x1), b = y1 - m * x1;
        return m * currentVal + b;
    },
    
    lerp: (v0, v1, amt, maxMove = 0, minDiff = 0.0001) => {
        let diff = v1 - v0;
        if (maxMove > 0) {
            diff = Math.min(diff, maxMove);
            diff = Math.max(diff, -maxMove);
        }
        if (Math.abs(diff) < minDiff) {
            return v1;
        }
        return v0 + diff * amt;
    },

    debounce (func, wait, immediate) {
        var timeout;
        wait = wait ? wait : 1000 / 60;
    
        return () => {
            var context = this, args = arguments;
            var later = () => {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    },
    
    loadImage (src) {
        return new Promise((resolve, reject) => {
            let img = new Image();
            img.onload  = () => { resolve(img) };
            img.onerror = () => { resolve(img) };
            img.src = src;
        });
    },

    randomInt (min, max) { return Math.round(Math.random() * (max - min) + min); },
    randomFloat (min, max) { return Math.random() * (max - min) + min; },
    randomModifier (magnitude, base = 0) { return base + (Math.random() * magnitude - (magnitude / 2)); },
    randomSign () { return Math.random(1) > 0.5 ? 1 : -1; },
    randomElement (array) { return array[Math.floor(Math.random() * array.length)]; },
    randomIndex (array) { return Math.floor(Math.random() * array.length); },
    radialRandom () { return ((Math.random() * 2 - 1) + (Math.random() * 2 - 1) + (Math.random() * 2 - 1)) / 2; },

    preloadHTMLImages (html) {
        let imgs = Utils.detectImages(html);

        if (!imgs || imgs.length === 0) {
            return Promise.all([]);
        }

        let loadedImgs = 0;

        let promises = [];
        imgs.forEach((src) => {
            // console.log("Load", src);
            promises.push(
                Utils.loadImage(src).then(() => {
                    loadedImgs++;
                    let event = new CustomEvent("imageLoaded", { detail : { value: loadedImgs, total: imgs.length } });
                    document.dispatchEvent(event);
                })
            );
        });

        return Promise.all(promises);
    },

    detectImages (html) {
        let imgs = [], m;
        const srcRegex = /<img[^>]+src="([^">]+)/g
        while (m = srcRegex.exec(html)) { imgs.push(m[1]); }

        return imgs;
    },

    setNewAttrs (html) {
        const classRegex = /data-bodyclass="(.[^"]+)"/g;
        const newBodyClass = classRegex.exec(html);
        if (newBodyClass) {
            document.body.className = newBodyClass[1];
        }
    }
};

export { Utils as default };
