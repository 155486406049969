const $ = require("jquery");

class GridDebug {
    constructor(nbCols, currentState = 1) {
        this.dom = {
            gridDebug: undefined,
            button: undefined
        };
        this.nbCols = nbCols;
        this.currentState = currentState;
        this.maxState = 3

        this.createDom();
        
        document.addEventListener('keyup', (e) => { if (e.code == 'KeyG') this.toggle(); });
    }

    createDom () {
        this.dom.gridDebug = $('<div class="gridDebug" data-state="' + this.currentState + '"><div class="container"><div class="row"></div></div></div>');
        this.dom.button = $('<button type="button" class="toggleGrid"> <svg width="21" height="20" viewBox="0 0 21 20" version="1" xmlns="http://www.w3.org/2000/svg"> <path d="M0 0h5v20H0zM16 0h5v20h-5zM8 0h5v20H8z" /> </svg> </button>');
        let row = this.dom.gridDebug.find('.row');
        for (let index = 0; index < this.nbCols; index++) {
            $('<div class="col-1"><div class="colOverlay"></div></div>').appendTo(row);
        }
        this.dom.gridDebug.appendTo($('body'));
        this.dom.button.appendTo($('body'));

        this.dom.button.on('click', () => { this.toggle() }); 
    }

    toggle () {
        this.currentState = this.currentState === this.maxState ? 0 : this.currentState + 1;
        this.dom.gridDebug.attr('data-state', this.currentState);
    }

}

export default GridDebug;