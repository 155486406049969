﻿import $ from "jquery";
import anime from 'animejs';
import barba from "@barba/core";
import LocomotiveScroll from 'locomotive-scroll';
import EmblaCarousel from 'embla-carousel'
import { setupPrevNextBtns, listenForPrevBtnClick, listenForNextBtnClick, disablePrevNextBtns } from "./utils/PrevNextButtons";
import lottie from "lottie-web";
import videojs from "video.js";
import "videojs-youtube";

import WindowUtils from './utils/WindowUtils';
import GridDebug from './utils/GridDebug';
import Utils from "./utils/Utils";

jQuery = $;

// new GridDebug(10,0);

// MOBILE DETECT
document.addEventListener("DOMContentLoaded", initDetect)

function initDetect() {
    window.addEventListener("resize", detectDevice);
    detectDevice();
}

detectDevice = () => {
    let orientation = window.orientation == 0 ? 'portrait' : 'landscape';
    
    $('html').attr('data-device-notice', WU.deviceType() == 'mobile' && orientation == 'landscape' && WU.w.w < 1000);

    if (scroll) { setTimeout(() => { scroll.update(); }, 400); }
}


const isProduct = () => document.body.classList.contains('page-template-tpl-produit');

const WU = new WindowUtils();
if (WU.isTouch()) {
    $('html').addClass('isTouch');
}
if (WU.isSafari()) {
    $('html').addClass('isSafari');
}

const productSequenceImages = [];
const productSequenceImagesPath = WU.isXS() ? '/wp-content/themes/flyingwhales/assets/images/product-mobile/' : '/wp-content/themes/flyingwhales/assets/images/product/';
let fileName;
for (let i = 0; i <= 818; i++) {
    fileName = productSequenceImagesPath + 'fw' + i.toString().padStart(3, '0') + '.jpg'
    productSequenceImages.push(fileName);

    if (!isProduct()) {
        Utils.loadImage(fileName);
    }
}

const requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;

const loadImages = (parent = document) => {
    // parent.querySelectorAll('.imageRender:not(.imageRender--loaded)').forEach((img) => {
    //     let url = img.getAttribute('data-url');
    //     let cover = img.querySelector('.imageRender_cover');

    //     Utils.loadImage(url).then(() => {
    //         cover.style.backgroundImage = 'url(' + url + ')';
    //         img.classList.add('imageRender--loaded');
    //     });
    // });
};

let scroll;

const createScroll = (parent = document) => {
    scroll = new LocomotiveScroll({
        el: parent.querySelector('.lcs[data-scroll-container]'),
        smooth: true,
        getSpeed: true,
        getDirection: true,
        gestureDirection: 'vertical',
        direction: 'vertical',
        tablet: {
            smooth: true
        },
        smartphone: {
            smooth: false
        }
    });

    scroll.stop();

    let s, l;
    let bg = document.querySelector('.backgroundAnim_bg');
    let bgBounds = bg.getBoundingClientRect();
    let pxToMove = bgBounds.height - WU.w.h;

    scroll.on('scroll', (args) => {
        if (WU.isXS()) return;

        s = args.scroll.y;
        l = args.limit.y;
        bg.style.transform = 'translateY(-' + pxToMove * (s / l) + 'px)';

        if (args.direction == 'down' && args.delta.y > WU.w.h * 0.1) {
            document.querySelector('.navbar').classList.remove('hidden');
            document.querySelector('.navbar').classList.add('showBg');
        } else {
            document.querySelector('.navbar').classList.remove('hidden');
            if (args.delta.y <= WU.w.h * 0.1) {
                document.querySelector('.navbar').classList.remove('showBg');
            }
        }

        if (typeof args.currentElements['team'] === 'object') {
            let data = args.currentElements['team'];
            let inner = data.el.querySelector('.team_inner');
            let progress = data.progress;
            let scale = 3;
            let percent = (progress - 0.05) / 0.75;
            let newScale = ((scale - (scale * percent)) + 1);

            if (percent >= 0 && percent <= 1) {
                anime.set(inner, {
                    scale: newScale
                });
            }
        }

        if (typeof args.currentElements['applications'] === 'object') {
            let data = args.currentElements['applications'];
            let wrap = data.el.querySelector('.applications_scroller');
            let progress = data.progress;
            let wrapW = wrap.clientWidth;
            let maxTranslate = wrapW + WU.w.w;
            let percent = progress;//(progress - 0.2) / (0.8 - 0.2);

            if (percent >= 0 && percent <= 1) {
                anime.set(wrap, {
                    translateX: maxTranslate * percent * -1
                })
            }
        }

        if (typeof args.currentElements['product'] === 'object') {
            let data = args.currentElements['product'];
            let progress = data.progress;
            // console.log(args);
        }
    });

    if (WU.isXS()) {
        let lastScrollTop = 0;

        window.addEventListener("scroll", () => { 
            let st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
            if (st > lastScrollTop && st > WU.w.h * 0.5){
                document.querySelector('.navbar').classList.remove('hidden');
                document.querySelector('.navbar').classList.add('showBg');
            } else {
                document.querySelector('.navbar').classList.remove('hidden');

                if (st <= WU.w.h * 0.75) {
                    document.querySelector('.navbar').classList.remove('showBg');
                }
            }

            lastScrollTop = st <= 0 ? 0 : st;
        }, false);
    }

    scroll.on('call', (value, way, obj) => {
        let calls = (typeof value === 'string') ? [value] : value;
        calls.forEach(call => {
            if (call === 'enterSlide') {
                if (way === 'enter') {
                    let slideId = obj.el.getAttribute('data-slide');

                    if (document.querySelector('.productSlide_inner.active')) {
                        document.querySelector('.productSlide_inner.active').classList.remove('active');
                    }

                    if (document.querySelector('.productSlide_inner[data-slide="' + slideId + '"]')) {
                        document.querySelector('.productSlide_inner[data-slide="' + slideId + '"]').classList.add('active');
                    }
                } else {
                    // console.log("Leave", obj.el.getAttribute('data-slide'));
                }
            } else{
                 if (call.includes('headerCover')) {
                if (way === 'enter') {
                    document.querySelector('.navbar').classList.add('navbar--light');
                } else {
                    document.querySelector('.navbar').classList.remove('navbar--light');
                }
            }  if (call.includes('initCycle')) {
                if (way === 'enter') {
                    initCycle(obj.el);
                }
            }    if (call.includes('blueBg')) {
                if (way === 'enter') {
                    document.querySelector('.navbar').classList.add('navbar--light');
                } else {
                    document.querySelector('.navbar').classList.remove('navbar--light');
                }
            }  if (call.includes('toggleVideo')) {
                let videoPlayer = obj.el.querySelector('video');
                if (videoPlayer && videoPlayer.getAttribute('src') != '') {
                    if (way === 'enter') {
                        videoPlayer.play();
                    } else {
                        videoPlayer.pause();
                    }
                }
            }
            }
        });
    });
};

const scrollTop = (force) => {
    if (!scroll) return;
    if (force) {
        scroll.scrollTo(0, { duration: 0, disableLerp: true });
    } else {
        scroll.scrollTo(0, { duration: 1000, easing: [0.90, 0.00, 0.00, 1.00] });
    }
};

const scrollTo = (elt) => {
    scroll.scrollTo(elt, { duration: 1000, easing: [0.90, 0.00, 0.00, 1.00] });
};

let DOM = {};

const opendSubmenu = (link) => {
    DOM.menu.querySelectorAll('.subNav').forEach((subnav) => {
        subnav.scrollTop = 0;
    });

    if (DOM.menu.querySelector('.subNav.active')) {
        let active = DOM.menu.querySelector('.subNav.active');
        anime({
            targets: active.querySelectorAll('li'),
            opacity: [1, 0],
            duration: 200,
            easing: 'linear'
        });
        active.classList.remove('active');
        DOM.menu.querySelector('.subNavs').classList.remove('opened');
    }

    if (link.classList.contains('hasSubnav')) {
        let subnavId = link.getAttribute('data-menu');
        let subnav = DOM.menu.querySelector('.subNav[data-menu="' + subnavId + '"]');
        subnav.classList.add('active');
        anime({
            targets: subnav.querySelectorAll('li'),
            opacity: [0, 1],
            translateY: [150, 0],
            duration: 900,
            easing: 'easeOutExpo',
            delay: anime.stagger(100)
        });
        DOM.menu.querySelector('.subNavs').classList.add('opened');
    }

    if (DOM.mainNav.querySelectorAll('a.active').length) {
        DOM.mainNav.querySelectorAll('a.active').forEach((l) => {
            l.classList.remove('active');
        });
    }

    link.classList.add('active');
};

const updateSubMenusHeight = () => {
    let maxHeight = document.querySelector('.subNavs').offsetHeight;
    document.querySelectorAll('.subNav').forEach((subnav) => {
        if (subnav.querySelector('.subNav_inner').offsetHeight > maxHeight) {
            subnav.classList.add('overflow');
        }
    });
    // document.querySelector('.subNavs').style.height = maxHeight + "px";
    // document.querySelector('.subNavs').classList.add('ready');
};

const initMenu = () => {
    DOM.menu = document.querySelector('.menu');
    DOM.toggle = document.querySelector('.menuToggle');
    DOM.mainNav = DOM.menu.querySelector('.mainNav');
    DOM.mainNavLinks = DOM.mainNav.querySelectorAll('a');
    DOM.subNavs = DOM.menu.querySelectorAll('.subNav');
    DOM.footerMenu = document.querySelector('.footer_menu');
    if (DOM.footerMenu) {
        DOM.footerMenuLinks = DOM.footerMenu.querySelectorAll('.openMenu');
    }

    // MENU OPEN/CLOSE BUTTON
    DOM.toggle.addEventListener('click', () => {
        DOM.toggle.classList.toggle('opened');
        DOM.menu.classList.toggle('opened');

        if (DOM.menu.classList.contains('opened')) {
            scroll.stop();
        } else {
            scroll.start();
        }
    });

    // SUBNAVS
    DOM.mainNavLinks.forEach((link) => {
        link.addEventListener('click', (e) => {
            if (link.classList.contains('hasSubnav')) {
                e.preventDefault();
            }

            opendSubmenu(link);
        });
    });

    DOM.menu.querySelectorAll('.subNavs_back').forEach((link) => {
        link.addEventListener('click', (e) => {
            e.preventDefault();
            
            if (DOM.mainNav.querySelectorAll('a.active').length) {
                DOM.mainNav.querySelectorAll('a.active').forEach((l) => {
                    l.classList.remove('active');
                });
            }
            
            DOM.menu.querySelector('.subNavs').classList.remove('opened');
        })
    });

    updateSubMenusHeight();
    window.addEventListener('resize', Utils.debounce(() => { updateSubMenusHeight(); }), { passive: true });

    // FOOTER LINKS
    if (DOM.footerMenuLinks) {
        DOM.footerMenuLinks.forEach((li) => {
            li.querySelector('a').addEventListener('click', function (e) {
                e.preventDefault();
                let target = this.getAttribute('href');
                let targetLink = DOM.menu.querySelector('.' + target.substring('1'));

                setTimeout(() => {
                    opendSubmenu(targetLink);
                }, DOM.toggle.classList.contains('opened') ? 0 : 400);


                scroll.stop();
                document.querySelector('.navbar').classList.remove('hidden');
                DOM.toggle.classList.add('opened');
                DOM.menu.classList.add('opened');
            })
        });
    }
};

const updateUrl = (url) => {
    history.pushState(null, '', url);
};

const initCarousels = () => {
    if (document.querySelectorAll('.carousel')) {
        document.querySelectorAll('.carousel').forEach((domElt) => {
            let domEmbla = domElt.querySelector('.embla');
            let domEmblaPrev = domElt.querySelector('.carousel_nav_prev');
            let domEmblaNext = domElt.querySelector('.carousel_nav_next');
            let loop = domEmbla.getAttribute('data-loop') && domEmbla.getAttribute('data-loop') === "true";
            let dragFree = domEmbla.getAttribute('data-dragfree') && domEmbla.getAttribute('data-dragfree') === "true";
            let domEmblaOptions = { align: "start", loop: loop, dragFree: dragFree };
            if (dragFree) {
                domEmblaOptions['containScroll'] = 'keepSnaps';
            }
            let domEmblaPlugins = [];
            let embla = EmblaCarousel(domEmbla, domEmblaOptions, domEmblaPlugins);
            setupPrevNextBtns(domEmblaPrev, domEmblaNext, embla);

            embla.on("select", disablePrevNextBtns);
            embla.on("init", disablePrevNextBtns);
            embla.on("init", () => { scroll.update(); });
        })
    }
};
const initJobForm = ()=>{
    const job_form = document.querySelector('.job_form')
    const jobForm_close = document.querySelector('.jobForm_close')

    if (job_form) {
        const form_openener = document.querySelectorAll('.formOpener')
        form_openener.forEach(opener=>{
            opener.addEventListener('click',()=>{
                job_form.classList.add('open')
                document.body.classList.add('disable-scroll')
                scroll.stop()
                document.querySelector('#jobemail').value = job_form.getAttribute('data-job-email')
            })
        })
        jobForm_close.addEventListener('click',()=>{
            job_form.classList.remove('open')
                document.body.classList.remove('disable-scroll')
                scroll.start()
        })
    }
}
const initCollapses = () => {
    if (document.querySelectorAll('.collapse')) {
        document.querySelectorAll('.collapse').forEach((collapse) => {
            let collapseTitle = collapse.querySelector('.collapse_title');
            let collapseContent = collapse.querySelector('.collapse_content');
            if (collapse.classList.contains('collapse--opened')) {
                let contentHeight = collapse.querySelector('.collapse_contentInner').offsetHeight;
                anime({
                    targets: collapseContent,
                    height: contentHeight,
                    easing: 'easeOutExpo',
                    duration: 0,
                    complete: () => { scroll.update(); }
                });
            }
            collapseTitle.addEventListener('click', (e) => {
                let contentHeight = collapse.querySelector('.collapse_contentInner').offsetHeight;
                collapse.classList.toggle('collapse--opened');
                anime({
                    targets: collapseContent,
                    height: collapse.classList.contains('collapse--opened') ? contentHeight : 0,
                    easing: 'easeOutExpo',
                    duration: 600,
                    complete: () => { scroll.update(); }
                });
            })
        });

        const resizeCollapsesContents = () => {
            if (document.querySelectorAll('.collapse--opened')) {
                document.querySelectorAll('.collapse--opened').forEach((collapse) => {
                    let contentHeight = collapse.querySelector('.collapse_contentInner').offsetHeight;
                    collapse.querySelector('.collapse_content').style.height = contentHeight + 'px';
                });
            }
        };

        window.addEventListener('resize', Utils.debounce(() => { resizeCollapsesContents() }), { passive: true });
    }
};

const initCycle = (cycle) => {
    // if (document.querySelector('.cycle')) {
        // let cycle = document.querySelector('.cycle');
        let steps = cycle.querySelectorAll('.cycle_step');
        let contents = cycle.querySelectorAll('.cycle_content');

        let progressBar = cycle.querySelector('.cycle_circle_progress_bar');
        let r = parseInt(progressBar.getAttribute('r'));
        let c = Math.PI * 2 * r;

        let progressBarMobile = cycle.querySelector('.cycle_circle_progress_mobile_bar');
        let rMobile = parseInt(progressBarMobile.getAttribute('r'));
        let cMobile = Math.PI * 2 * rMobile;

        let currentStep = 0;
        let cycleInterval = null;

        let activateStep = (step) => {
            let stepNum = parseInt(step.getAttribute('data-step'));

            let pct = Math.round(((1 - (stepNum - 1) / steps.length)) * c);
            progressBar.style.strokeDashoffset = pct + "px";
            
            let pctMobile = Math.round(((1 - (stepNum - 1) / steps.length)) * cMobile);
            progressBarMobile.style.strokeDashoffset = pctMobile + "px";

            steps.forEach((elt, index) => {
                if (index <= stepNum - 1) {
                    elt.classList.add('cycle_step--opened');
                }
                if (index == stepNum - 1) {
                    elt.classList.add('cycle_step--active');
                }
                if (index > stepNum - 1) {
                    elt.classList.remove('cycle_step--opened');
                    elt.classList.remove('cycle_step--active');
                }
            });

            contents.forEach((elt, index) => {
                if (index == stepNum - 1) {
                    elt.classList.add('cycle_content--active');
                    let video = elt.querySelector('video');
                    video.currentTime = 0;
                    video.play();
                } else {
                    elt.classList.remove('cycle_content--active');
                    let video = elt.querySelector('video');
                    video.currentTime = 0;
                    video.pause();
                }
            });
        };

        setTimeout(() => {
            cycle.classList.add('ready');
            activateStep(cycle.querySelector('.cycle_step--1'));
        }, 8000);

        cycleInterval = setInterval(() => {
            if (currentStep == steps.length) {
                clearInterval(cycleInterval);
                return;
            }

            currentStep++;
            activateStep(cycle.querySelector('.cycle_step--' + currentStep));
        }, 4000);

        cycle.querySelectorAll('.cycle_step_button').forEach((button) => {
            let step = button.closest('.cycle_step');

            button.addEventListener('click', () => {
                clearInterval(cycleInterval);
                activateStep(step);
            });
        })
    // }
};

const initLotties = () => {
    document.querySelectorAll('.lottie').forEach((elt) => {
        let url = elt.getAttribute('data-url');
        if (url) {
            let anim = lottie.loadAnimation({
                container: elt,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: url
            });

            anim.addEventListener('data_ready', () => {
                if (scroll) {
                    scroll.update();
                }
            });
        }
    });
};

const initPlayers = () => {
    var players = document.querySelectorAll(".video-js-default");
    players.forEach(function (player) {
        videojs(player, null, () => { scroll.update(); });
    });
};

const initTextareas = () => {
    const tx = document.getElementsByTagName("textarea");
    for (let i = 0; i < tx.length; i++) {
        tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
        tx[i].addEventListener("input", OnInput, false);
    }

    function OnInput() {
        this.style.height = "auto";
        this.style.height = (this.scrollHeight) + "px";
    }
};

const initProduct = () => {
    if (document.querySelector('.productRender')) {
        new ScrollSequence({
            container: '.productRender',
            images: productSequenceImages,
            imagesRoot: null,
            priorityFrames: [],//[0, 240, 330, 475, 615, 710],
            cover: true,
            ready: () => {
                scroll.start();
                document.body.classList.add('ready');
                document.querySelector('.productButton').classList.add('productButton--ready');
            }
        });
    }
};

const initCursors = () => {
    if (document.querySelectorAll('.fullLink').length) {
        document.querySelectorAll('.fullLink').forEach((link) => {
            let cursor = link.querySelector('.fullLink_cursor');
            link.addEventListener('mousemove', e => {
                let rect = e.target.getBoundingClientRect();
                let x = e.clientX - rect.left;
                let y = e.clientY - rect.top;
                cursor.style.transform = 'translate(' + x + 'px,' + y + 'px)';
            });
        })
    }
};


const initFileUpload = () => {
    let inputs = document.querySelectorAll('.uploadWrap');
	Array.prototype.forEach.call( inputs, function( field )
	{
        let input = field.querySelector('input[type="file"]');
        let inputWrap = field.querySelector('.file-upload');
        let label = field.querySelector('label');
		let labelVal = label.innerHTML;

		input.addEventListener( 'change', function( e )
		{
			let fileName = '';
			if( this.files && this.files.length > 1 )
				fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
			else
				fileName = e.target.value.split( '\\' ).pop();

			if( fileName )
				label.innerHTML = fileName;
			else
				label.innerHTML = labelVal;
		});
	});
}

let mmDOM = {
    parent: document.querySelector('.mediaModal'),
    render: document.querySelector('.mediaModal_render'),
    cover: document.querySelector('.mediaModal_render_cover'),
    video: document.querySelector('.mediaModal_render_video'),
    cursor: document.querySelector('.mediaModal_cursor'),
    close: document.querySelector('.mediaModal_close'),
    closeButton: document.querySelector('.mediaModal_render_video_close'),
};
let mmVideo, mmVideoPlayer;

const initMediaModal = () => {
    document.querySelectorAll('[data-modal]').forEach((button) => {
        let url = button.getAttribute('data-modal');
        let type = button.getAttribute('data-type');

        button.addEventListener('click', (e) => {
            e.preventDefault();
            openMediaModal(type, url);
        });
    });
};

const openMediaModal = (type, url) => {
    mmDOM.parent.setAttribute('data-type', type);
    mmDOM.parent.style.display = "block";
    if (type == 'image') {
        Utils.loadImage(url).then(() => {
            mmDOM.cover.src = url;
            setTimeout(() => {
                mmDOM.parent.classList.add('visible');
                mmDOM.render.classList.add('ready');
                initMediaModalEvents('image');
            }, 500);
        })
    }
    else if (type == 'video') {
        mmDOM.parent.classList.add('visible');
        mmDOM.render.classList.add('ready');

        mmVideo = document.createElement("video");
        mmVideo.classList.add('video-js');
        mmVideo.classList.add('vjs-default-skin');
        mmVideo.classList.add('vjs-fluid');
        mmDOM.video.appendChild(mmVideo);

        mmVideoPlayer = videojs(mmDOM.video.querySelector('.video-js'), {
            controls: true,
            autoplay: true,
            techOrder: ["youtube"],
            sources: [{
                type: "video/youtube",
                src: url
            }]
        });

        initMediaModalEvents('video');
    }
};

const initMediaModalEvents = (type) => {

    let coverBounding, maxMovY, pct;
    
    const modalResize = () => {
        coverBounding = mmDOM.cover.getBoundingClientRect();
        maxMovY = coverBounding.height - WU.w.h;

        if (maxMovY <= 0) {
            mmDOM.cover.style.transform = "translateY(" + (WU.w.h - coverBounding.height) / 2 + "px)";
        }
    };

    if (type == 'image') {
        modalResize();
        window.addEventListener('resize', modalResize);
    }

    mmDOM.parent.addEventListener('mousemove', (e) => {
        if (type == 'image') {
            if (maxMovY > 0) {
                pct = e.clientY / WU.w.h;
                mmDOM.cover.style.transform = "translateY(" + (pct * maxMovY * -1) + "px)";
            }
        }

        mmDOM.cursor.style.transform = 'translate(' + e.clientX + 'px,' + e.clientY + 'px)';

        e.stopPropagation();
    });

    mmDOM.close.addEventListener('click', closeMediaModal)
    mmDOM.closeButton.addEventListener('click', () => {
        mmVideoPlayer.dispose();
        closeMediaModal();
    })
};

const closeMediaModal = () => {
    mmDOM.parent.classList.remove('visible');
    setTimeout(() => {
        mmDOM.render.classList.remove('ready');
        mmDOM.cover.style.transform = "translateY(0)";
        mmDOM.parent.style.display = "none";
    }, 1000);
}; 

const page = () => {
    if (!isProduct()) {
        scroll.start();
    }

    loadImages();

    let url = new URL(window.location);

    initMenu();

    initCarousels();
    initCollapses();
    initJobForm();
    // initCycle();
    initLotties();
    initPlayers();
    initTextareas();
    initProduct();
    initCursors();
    initMediaModal();

    initFileUpload();
    document.querySelectorAll('[data-scrollto]').forEach((link) => {
        link.addEventListener('click', (e) => {
            e.preventDefault();
            let attr = link.getAttribute('data-scrollto');
            let elt;
            if (attr) {
                elt = document.querySelector(attr);
            }
            if (elt) {
                scrollTo(elt);
            }
        });
    })

    let doJobsAjax = function () {
        $('.pSection--jobs').find('.ajaxList').addClass('ajaxList--loading');

        $.ajax({
            url: ajaxVars.url,
            type: 'get',
            data: {
                action: 'jobs_ajax',
                city: url.searchParams.get('city')
            },
            success: function (result) {
                $('.pSection--jobs').find('.ajaxList').removeClass('ajaxList--loading').find('.ajaxList_results').html($(result).html());
                initCollapses();
                loadImages();
                scroll.update();
            },
            error: function (result) { console.log("ERROR", result); },
        });
    };

    $('.city_filter').find('a').on('click', function (e) {
        e.preventDefault();

        url.searchParams.delete('city');

        if ($(this).hasClass('active')) {
            if ($(this).attr('data-value') != 'all') {
                $(this).removeClass('active');
            }
        } else {
            $('.city_filter').find('.active').removeClass('active');
            $(this).addClass('active');
        }

        if ($(this).hasClass('active')) {
            if ($(this).attr('data-value') == 'all') {
                url.searchParams.delete('city');
            } else {
                url.searchParams.set('city', $(this).attr('data-value'));
            }
        } else {
            url.searchParams.delete('city');
        }

        updateUrl(url.href);
        doJobsAjax();
    });


    $('.city_filter_selector').on('change', function (e) {
        e.preventDefault();
        let value = $(this).val();
        
        if (value == 'all') {
            url.searchParams.delete('city');
        } else {
            url.searchParams.set('city', value);
        }

        updateUrl(url.href);
        doJobsAjax();
    });



    let articlesPaged = url.searchParams.has('pg') ? url.searchParams.get('pg') : 1;

    let doArticlesAjax = function (append = false, categoryForced = null) {
        if (append) {
            $('.articlesList').find('.loadMoreButton').addClass('loadMoreButton--loading');
        } else {
            $('.articlesList').find('.ajaxList').addClass('ajaxList--loading');
        }

        $.ajax({
            url: ajaxVars.url,
            type: 'get',
            data: {
                action: 'articles_ajax',
                paged: articlesPaged,
                offset: 1,
                category: url.searchParams.get('category'),
                categoryForced: categoryForced,
                search: url.searchParams.get('search'),
            },
            success: function (result) {
                if (append) {
                    $('.articlesList').find('.loadMoreButton').remove();
                    $('.articlesList').find('.ajaxList').append($(result).html());
                } else {
                    $('.articlesList').find('.ajaxList').removeClass('ajaxList--loading').find('.ajaxList_results').html($(result).html());
                }
                initBlogLoadMore();
                loadImages();
                scroll.update();
            },
            error: function (result) { console.log("ERROR", result); },
        });
    };

    $('.newsCategories').find('a').on('click', function (e) {
        e.preventDefault();

        articlesPaged = 1;
        url.searchParams.delete('pg');

        if ($(this).hasClass('active')) {
            if ($(this).attr('data-value') != 'all') {
                $(this).removeClass('active');
            }
        } else {
            $('.newsCategories .active').removeClass('active');
            $(this).addClass('active');
        }

        if ($(this).hasClass('active')) {
            if ($(this).attr('data-value') == 'all') {
                url.searchParams.delete('category');
            } else {
                url.searchParams.set('category', $(this).attr('data-value'));
            }
        } else {
            url.searchParams.delete('category');
        }

        updateUrl(url.href);
        doArticlesAjax();
    });

    $('.newsCategoriesMobile_selector').on('change', function (e) {
        e.preventDefault();
        let value = $(this).val();

        articlesPaged = 1;
        url.searchParams.delete('pg');
        
        if (value == 'all') {
            url.searchParams.delete('category');
        } else {
            url.searchParams.set('category', value);
        }

        updateUrl(url.href);
        doArticlesAjax();
    });


    let initBlogLoadMore = () => {
        $('.articlesList').find('.ajaxList').find('.loadMoreButton').on('click', function () {
            $(this).attr('disabled', 'disabled');
            articlesPaged++;
            url.searchParams.set('pg', articlesPaged);
            updateUrl(url.href);
            doArticlesAjax(true);
        });
    };

    initBlogLoadMore();
};

const initPage = () => {
    createScroll();
    WU.scrollToTop();
    page();
};

const hideLoading = (delay = 800) => {
    anime({
        targets: ['.loadingScreen_labels', '.loadingScreen_progress'],
        opacity: [1, 0],
        duration: 800,
        delay: delay,
    });

    anime({
        targets: '.loadingScreen',
        translateY: '-101%',
        easing: 'easeInOutExpo',
        duration: 1200,
        delay: delay + 100,
    });

    anime({
        targets: '.loadingScreen_inner',
        translateY: '101%',
        easing: 'easeInOutExpo',
        duration: 1200,
        delay: delay + 100,
        complete: () => {
            document.querySelector('.loadingScreen_progress_value').innerHTML = '0';
            document.querySelector('.loadingScreen_progress').style.display = 'none';
            anime.set('.loadingScreen_progress_bar', { translateY: '100%' });
            document.querySelector('.loadingScreen_labels').setAttribute('data-state', 0);
            document.querySelector('.loadingScreen_labels').style.display = 'none';
        }
    });

    // Animate header elements
    setTimeout(() => {
        let elementsToAnimate = [];

        if (WU.isXS()) {
            elementsToAnimate.push({ targets: '.header .st1 .titleRow', opacity: [0, 1] });
        } else {
            elementsToAnimate.push({ targets: '.header .st1 .titleRow > p', translateY: ['101%', '0%'], stagger: 200 });
        }

        elementsToAnimate.push({ targets: '.header_content', delay: 150 });
        elementsToAnimate.push({ targets: '.header .productButton', delay: 300 });
        elementsToAnimate.push({ targets: '.topNews .st2' });
        elementsToAnimate.push({ targets: '.articleSurtitle' });
        elementsToAnimate.push({ targets: '.pSection--form .container', delay: 300 });
        elementsToAnimate.push({ targets: '.header_cover--centered', delay: 300 });

        elementsToAnimate.forEach(element => {
            if (document.querySelector(element.targets)) { 
                let options = {
                    translateY: ['4rem', '0'],
                    opacity: [0, 1],
                    easing: 'easeOutExpo',
                    delay: 0,
                    ...element
                };

                if (element.stagger) {
                    options.delay = anime.stagger(element.stagger);
                }

                anime(options);
            }
        });
    }, delay + 900);
}

(function () {

    Utils.preloadHTMLImages(document.body.innerHTML).then(() => {
        initPage();
        hideLoading();
    });

    document.addEventListener("imageLoaded", function (e) {
        let pct = Math.ceil((e.detail.value / e.detail.total) * 100);

        document.querySelector('.loadingScreen_progress_value').innerHTML = pct;
        anime.set('.loadingScreen_progress_bar', { translateY: (100 - pct) + "%" });

        if (pct < 50) {
            document.querySelector('.loadingScreen_labels').setAttribute('data-state', 0);
        } else if (pct >= 50) {
            document.querySelector('.loadingScreen_labels').setAttribute('data-state', 1);
        }
    });

    barba.hooks.enter((data) => {
        scrollTop(true);
    });

    barba.hooks.after(() => {
        scroll.update();
    });

    barba.init({
        debug: false,
        timeout: 10000,
        transitions: [
            {
                name: 'default',
                leave() {
                    return new Promise(resolve => {
                        anime({
                            targets: '.loadingScreen',
                            translateY: ['101%', '0%'],
                            easing: 'easeInOutExpo',
                            duration: 1200,
                        });

                        anime({
                            targets: '.loadingScreen_inner',
                            translateY: ['-101%', '0%'],
                            easing: 'easeInOutExpo',
                            duration: 1200,
                            complete: resolve
                        });

                        anime({
                            targets: ['.loadingScreen_labels', '.loadingScreen_progress'],
                            delay: 400,
                            duration: 800,
                            opacity: [0, 1]
                        });
                    });
                },
                enter(data) {
                    let done = this.async();
                    Utils.setNewAttrs(data.next.html);
                    Utils.preloadHTMLImages(data.next.html).then(() => {
                        done();

                        setTimeout(() => {
                            page();
                        }, 600);

                        hideLoading();
                    });
                    document.querySelectorAll('div.wpcf7 > form').forEach(element => {
                        wpcf7.init( element );
                    });
                }
            }
        ]
    });


})();




// ----- PRODUCT PAGE

class EventEmitter {
    listeners = {}
    addListener(eventName, fn) {
        this.listeners[eventName] = this.listeners[eventName] || [];
        this.listeners[eventName].push(fn);
        return this;
    }
    on(eventName, fn) {
        return this.addListener(eventName, fn);
    }
    once(eventName, fn) {
        this.listeners[eventName] = this.listeners[eventName] || [];
        const onceWrapper = () => {
            fn();
            this.off(eventName, onceWrapper);
        }
        this.listeners[eventName].push(onceWrapper);
        return this;
    }
    off(eventName, fn) {
        return this.removeListener(eventName, fn);
    }
    removeListener(eventName, fn) {
        let lis = this.listeners[eventName];
        if (!lis) return this;
        for (let i = lis.length; i > 0; i--) {
            if (lis[i] === fn) {
                lis.splice(i, 1);
                break;
            }
        }
        return this;
    }
    emit(eventName, ...args) {
        let fns = this.listeners[eventName];
        if (!fns) return false;
        fns.forEach((f) => {
            f(...args);
        });
        return true;
    }
    listenerCount(eventName) {
        let fns = this.listeners[eventName] || [];
        return fns.length;
    }
    rawListeners(eventName) {
        return this.listeners[eventName];
    }
}

class ImgLoader extends EventEmitter {
    constructor(opts) {
        super();
        this.images = opts.imgsRef;
        this.imageNames = opts.images;
        this.imagesRoot = opts.imagesRoot;
        this.sequenceLength = opts.images.length;
        this.priorityFranes = opts.priorityFrames;
        this.complete = false;
        this.loadIndex = 0;
        this.minimumIndex = WU.isXS() ? Math.round(this.imageNames.length * 0.65) : Math.round(this.imageNames.length * 0.4);
        
        this.priorityQueue = this.createPriorityQueue();
        this.loadingQueue = this.createLoadingQueue();

        this.loadNextImage();
    }

    loadImage(e) {
        if (this.images[e]) {
            return this.loadNextImage();
        }
        const onLoad = () => {
            img.removeEventListener('load', onLoad);
            this.images[e] = img;

            if (e == this.minimumIndex) {
                this.emit('MINIMUM_LOADED');
            }
            
            if (e === 0) {
                this.emit('FIRST_IMAGE_LOADED');
            }

            this.loadIndex++;
            this.emit('IMAGE_LOADED');

            this.loadNextImage();
        }
        const img = new Image;
        img.addEventListener('load', onLoad);
        img.src = (this.imagesRoot ? this.imagesRoot : '') + this.imageNames[e];
    }

    loadNextImage() {
        if (this.priorityQueue.length) {
            this.loadImage(this.priorityQueue.shift());
            if (!this.priorityQueue.length) {
                this.emit('PRIORITY_IMAGES_LOADED');
            }
        } else if (this.loadingQueue.length) {
            this.loadImage(this.loadingQueue.shift())
        } else {
            this.complete = true;
            this.emit('IMAGES_LOADED');
        }
    }

    createPriorityQueue() {
        const p = this.priorityFrames || [];
        if (!p.length) {
            p.push(0);
            p.push(Math.round(this.sequenceLength / 2));
            p.push(this.sequenceLength - 1);
        }
        return p;
    }

    createLoadingQueue() {
        return this.imageNames.map((s, i) => i).sort((e, n) => {
            return n;//Math.abs(e - this.sequenceLength / 2) - Math.abs(n - this.sequenceLength / 2)
        });
    }
}

class Canvas {
    constructor(e) {
        this.images = e.images;
        this.container = e.container;
        this.cover = e.cover;
        this.displayIndex = 0;
    }

    setup() {
        this.canvas = document.createElement("canvas");
        this.container.appendChild(this.canvas);
        this.ctx = this.canvas.getContext('2d')

        window.addEventListener('resize', () => this.resize());
        this.resize();
    }

    renderIndex(e) {
        if (this.images[e]) {
            return this.drawImage(e);
        }
        // Find closest loaded image
        for (var t = Number.MAX_SAFE_INTEGER, r = e; r >= 0; r--)
            if (this.images[r]) {
                t = r;
                break
            }
        for (var n = Number.MAX_SAFE_INTEGER, i = e, o = this.images.length; i < o; i++)
            if (this.images[i]) {
                n = i;
                break
            }
        this.images[t] ? this.drawImage(t) : this.images[n] && this.drawImage(n)
    }

    drawImage(e) {
        this.displayIndex = e,
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        const x = Math.floor((this.canvas.width - this.images[this.displayIndex].naturalWidth) / 2);
        const y = Math.floor((this.canvas.height - this.images[this.displayIndex].naturalHeight) / 2);
        if (this.cover) {

            this.drawImageCover(this.ctx, this.images[this.displayIndex]);
        } else {
            this.ctx.drawImage(this.images[this.displayIndex], x, y);
        }
    }

    resize() {
        const w = this.container.clientWidth;
        const h = this.container.clientHeight;
        this.canvas.style.height = `${h}px`;
        this.canvas.style.width = `${w}px`;
        this.canvas.height = h;
        this.canvas.width = w;

        this.renderIndex(this.displayIndex);
    }

    /**
   * By Ken Fyrstenberg Nilsen
   *
   * drawImageProp(context, image [, x, y, width, height [,offsetX, offsetY]])
   *
   * If image and context are only arguments rectangle will equal canvas
  */
    drawImageCover(ctx, img, x, y, w, h, offsetX, offsetY) {

        if (arguments.length === 2) {
            x = y = 0;
            w = ctx.canvas.width;
            h = ctx.canvas.height;
        }

        // default offset is center
        offsetX = typeof offsetX === "number" ? offsetX : 0.5;
        offsetY = typeof offsetY === "number" ? offsetY : 0.5;

        // keep bounds [0.0, 1.0]
        if (offsetX < 0) offsetX = 0;
        if (offsetY < 0) offsetY = 0;
        if (offsetX > 1) offsetX = 1;
        if (offsetY > 1) offsetY = 1;

        var iw = img.width,
            ih = img.height,
            r = Math.min(w / iw, h / ih),
            nw = iw * r,   // new prop. width
            nh = ih * r,   // new prop. height
            cx, cy, cw, ch, ar = 1;

        // decide which gap to fill    
        if (nw < w) ar = w / nw;
        if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
        nw *= ar;
        nh *= ar;

        // calc source rectangle
        cw = iw / (nw / w);
        ch = ih / (nh / h);

        cx = (iw - cw) * offsetX;
        cy = (ih - ch) * offsetY;

        // make sure source rectangle is valid
        if (cx < 0) cx = 0;
        if (cy < 0) cy = 0;
        if (cw > iw) cw = iw;
        if (ch > ih) ch = ih;

        // fill image in dest. rectangle
        ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
    }
}

class ScrollSequence {
    constructor(opts) {
        this.opts = {
            container: 'body',
            imagesRoot: '',
            cover: false,
            ...opts
        }

        this.container = typeof opts.container === 'object' ?
            opts.container :
            document.querySelector(opts.container);

        this.countImages = opts.images.length
        this.images = Array(this.countImages);
        this.imagesToLoad = opts.images;
        this.priorityFrames = opts.priorityFrames;

        this.loader = new ImgLoader({
            imgsRef: this.images,
            images: this.imagesToLoad,
            imagesRoot: this.opts.imagesRoot,
            priorityFrames: this.priorityFrames,
            ready: opts.ready
        });

        this.canvas = new Canvas({
            container: this.container,
            images: this.images,
            cover: this.opts.cover
        });

        this.init();
    }

    init() {
        this.canvas.setup();
        this.loader.once('FIRST_IMAGE_LOADED', () => {
            this.canvas.renderIndex(0);
            // console.log('FIRST_IMAGE_LOADED');
        })
        this.loader.once('PRIORITY_IMAGES_LOADED', () => {
            let imgs = this.countImages;
            let progress = 0, mapToIndex = 0;
            scroll.on('scroll', (args) => {

                if (typeof args.currentElements['product'] === 'object') {
                    progress = args.currentElements['product'].progress;
                    mapToIndex = Math.floor(progress * imgs);
                    requestAnimationFrame(() => this.canvas.renderIndex(mapToIndex));
                }
            });
        })
        this.loader.once('IMAGES_LOADED', () => {
            // console.log('Sequence Loaded');
        })
        this.loader.once('MINIMUM_LOADED', () => {
            this.opts.ready();
        })
        this.loader.once('IMAGE_LOADED', (args) => {
            let pct = this.loader.loadIndex / this.loader.minimumIndex;
            if (pct >= 0 && pct < 1) {
                document.querySelector('.productLoading').innerHTML = Math.round(pct * 100) + '%';

            }
        })
    }
}
